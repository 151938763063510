var render = function () {
  var _vm$selectedListClien, _vm$selectedListClien2, _vm$$route$params, _vm$$route$params2, _vm$$route$params3, _vm$client, _vm$client$firstName, _vm$client2, _vm$client3, _vm$client$phone, _vm$client4, _vm$selectedListClien3, _vm$selectedListClien4, _vm$client5, _vm$selectedListClien7, _vm$selectedListClien8, _vm$selectedListClien9, _vm$selectedListClien10, _vm$selectedListClien11, _vm$selectedListClien12, _vm$selectedListClien13, _vm$selectedListClien14;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "w": "100%"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "0",
      "pb": "20px",
      "paths": _vm.breadcrumbPath
    }
  }), _c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mb": ['0', '16px'],
      "p": ['1rem', '30px'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "width": "100%"
    }
  }, [_c('DownloadMealPlan', {
    attrs: {
      "type": ['review', 'revisi', 'sent', 'read'].includes((_vm$selectedListClien = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien === void 0 ? void 0 : _vm$selectedListClien.status) ? 'preview-with-download' : !!((_vm$selectedListClien2 = _vm.selectedListClientMealPlanDetail) !== null && _vm$selectedListClien2 !== void 0 && _vm$selectedListClien2.status) ? 'preview' : '',
      "program-id-local": (_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.programId,
      "month": (_vm$$route$params2 = _vm.$route.params) === null || _vm$$route$params2 === void 0 ? void 0 : _vm$$route$params2.month,
      "client-id-local": (_vm$$route$params3 = _vm.$route.params) === null || _vm$$route$params3 === void 0 ? void 0 : _vm$$route$params3.clientId
    }
  }), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "gap": "20px",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "w": ['80px'],
      "h": ['80px'],
      "object-fit": "cover",
      "border-radius": "80px",
      "src": _vm.getPhotoUser((_vm$client = _vm.client) === null || _vm$client === void 0 ? void 0 : _vm$client.photoUrl)
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px'],
      "font-weight": "500",
      "color": "neutral.black",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s((_vm$client$firstName = (_vm$client2 = _vm.client) === null || _vm$client2 === void 0 ? void 0 : _vm$client2.firstName) !== null && _vm$client$firstName !== void 0 ? _vm$client$firstName : '...') + " " + _vm._s((_vm$client3 = _vm.client) === null || _vm$client3 === void 0 ? void 0 : _vm$client3.lastName) + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['14px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s((_vm$client$phone = (_vm$client4 = _vm.client) === null || _vm$client4 === void 0 ? void 0 : _vm$client4.phone) !== null && _vm$client$phone !== void 0 ? _vm$client$phone : '...') + " ")])], 1)], 1), (_vm$selectedListClien3 = _vm.selectedListClientMealPlanDetail) !== null && _vm$selectedListClien3 !== void 0 && _vm$selectedListClien3.status ? _c('ChipStatus2', {
    attrs: {
      "status": (_vm$selectedListClien4 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien4 === void 0 ? void 0 : _vm$selectedListClien4.status
    }
  }) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "variant-color": "secondary",
      "color": "primary.400",
      "border-radius": "30px",
      "font-size": "18px",
      "padding": "0px 20px",
      "font-weight": "600",
      "is-disabled": !((_vm$client5 = _vm.client) !== null && _vm$client5 !== void 0 && _vm$client5.id)
    },
    on: {
      "click": _vm.onOpenQuestionnaire
    }
  }, [_vm._v(" Formulir Gizi ")]), _c('c-menu', {
    attrs: {
      "auto-select": false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$$route$params4;

        var isOpen = _ref.isOpen;
        return [_c('c-menu-button', {
          attrs: {
            "variant": "outline",
            "variant-color": "primary",
            "font-weight": "500",
            "font-size": "18px",
            "gap": "14px",
            "padding-inline": "20px",
            "border-radius": "40px"
          }
        }, [_vm._v(" Panduan Makan Bulan ke-" + _vm._s(((_vm$$route$params4 = _vm.$route.params) === null || _vm$$route$params4 === void 0 ? void 0 : _vm$$route$params4.month) || 'x') + " "), _c('c-box', {
          attrs: {
            "width": "14px",
            "height": "14px",
            "transform": isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-arrow-down.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#008C81"
          }
        })], 1)], 1), _c('c-menu-list', {
          attrs: {
            "padding": "0px"
          }
        }, _vm._l(_vm.optionListClientMealPlan, function (opt) {
          return _c('c-menu-item', {
            key: opt.month,
            attrs: {
              "padding": "8px 16px",
              "font-weight": "500",
              "font-size": "18px",
              "background-color": opt.month === _vm.month ? 'primary.50' : 'neutral.white',
              "is-disabled": opt.isDisabled
            },
            on: {
              "click": function click($event) {
                return _vm.onChangeMonth(opt);
              }
            }
          }, [_vm._v(" Panduan Makan Bulan ke-" + _vm._s(opt.month) + " ")]);
        }), 1)];
      }
    }])
  }), _c('c-menu', {
    attrs: {
      "auto-select": false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _vm$selectedListClien5, _vm$selectedListClien6;

        var isOpen = _ref2.isOpen;
        return [_c('c-menu-button', {
          attrs: {
            "is-active": isOpen,
            "variant-color": "primary",
            "variant": "outline",
            "display": "flex",
            "justify-content": "space-between",
            "font-size": "18px",
            "border-radius": "40px",
            "padding-inline": "20px",
            "gap": "14px",
            "font-weight": "500"
          }
        }, [_vm._v(" Aksi "), _c('c-box', {
          attrs: {
            "transform": isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-arrow-down.svg'),
            "height": "14px",
            "width": "14px",
            "fill": "#008C81"
          }
        })], 1)], 1), _c('c-menu-list', {
          attrs: {
            "padding": "0px",
            "placement": "bottom-end"
          }
        }, [_c('c-menu-item', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              ':hover': {
                background: 'primary.50'
              }
            },
            expression: "{\n                ':hover': {\n                  background: 'primary.50',\n                }\n              }"
          }],
          attrs: {
            "color": "primary.400",
            "font-size": "18px",
            "font-weight": "500",
            "padding-inline": "20px",
            "padding-block": "10px",
            "border-bottom-width": "1px",
            "border-bottom-color": "neutral.superLightGray",
            "is-disabled": ['draft', 'revisi'].includes((_vm$selectedListClien5 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien5 === void 0 ? void 0 : _vm$selectedListClien5.status)
          },
          on: {
            "click": _vm.onClickRevision
          }
        }, [_vm._v(" Revisi ")]), _c('c-menu-item', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              ':hover': {
                background: 'primary.50'
              }
            },
            expression: "{\n                ':hover': {\n                  background: 'primary.50',\n                }\n              }"
          }],
          attrs: {
            "color": "primary.400",
            "font-size": "18px",
            "font-weight": "500",
            "padding-inline": "20px",
            "padding-block": "10px",
            "is-disabled": ((_vm$selectedListClien6 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien6 === void 0 ? void 0 : _vm$selectedListClien6.status) !== 'review'
          },
          on: {
            "click": function click($event) {
              _vm.isOpenPopupConfirmSendToClient = true;
            }
          }
        }, [_vm._v(" Kirim ke Klien ")])], 1)];
      }
    }])
  })], 1)], 1), _c('c-box', {
    attrs: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "background-color": "#F3B965",
      "padding": "8px 16px",
      "justify-content": "space-between",
      "align-items": "center",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500"
    }
  }, [_vm._v(" Status Panduan Makan ")]), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "400",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(((_vm$selectedListClien7 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien7 === void 0 ? void 0 : _vm$selectedListClien7.status) || '-') + " ")])], 1), _c('c-grid', {
    attrs: {
      "height": "100%",
      "width": "100%",
      "min-width": "600px",
      "template-columns": "auto 1fr",
      "padding": "4px 32px",
      "background-color": "#FFFCDD",
      "border-bottom-left-radius": "8px",
      "border-bottom-right-radius": "8px"
    }
  }, [_c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "10px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px"
    }
  }, [_vm._v(" Ahli Gizi ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "10px 0 10px 40px"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedListClientMealPlanDetail.nutritionistFirstName) + " " + _vm._s(_vm.selectedListClientMealPlanDetail.nutritionistLastName) + " ")]), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "10px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px"
    }
  }, [_vm._v(" Dikirim pada ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "padding-left": "16px",
      "display": "flex",
      "align-items": "center",
      "padding": "10px 0 10px 40px"
    }
  }, [_c('c-text', [_vm._v(" " + _vm._s((_vm$selectedListClien8 = _vm.selectedListClientMealPlanDetail) !== null && _vm$selectedListClien8 !== void 0 && _vm$selectedListClien8.createdAt ? _vm.formatDateV2((_vm$selectedListClien9 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien9 === void 0 ? void 0 : _vm$selectedListClien9.createdAt, 7) : '-') + " ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "padding": "16px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px"
    }
  }, [_vm._v(" Catatan Client Manager ")])], 1), _c('c-grid-item', {
    attrs: {
      "border-bottom": "1px solid #C4C4C4",
      "display": "flex",
      "align-items": "center",
      "padding": "16px 0 16px 40px",
      "transition": "all 1s",
      "overflow": "hidden"
    }
  }, [_c('c-text', {
    ref: "ooa12",
    staticClass: "line-clamp-1",
    attrs: {
      "width": "100%"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s((_vm$selectedListClien10 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien10 === void 0 ? void 0 : _vm$selectedListClien10.noteFromClientManager)
    }
  })]), (_vm$selectedListClien11 = _vm.selectedListClientMealPlanDetail) !== null && _vm$selectedListClien11 !== void 0 && _vm$selectedListClien11.noteFromClientManager ? _c('c-button', {
    attrs: {
      "variant": "link",
      "display": "contents",
      "font-weight": "400",
      "color": "primary.400"
    },
    on: {
      "click": function click($event) {
        return _vm.onOpenNoteList('clientManager');
      }
    }
  }, [_vm._v(" Selengkapnya ")]) : _vm._e()], 1), _c('c-grid-item', {
    attrs: {
      "display": "flex",
      "padding": "10px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "400",
      "font-size": "16px"
    }
  }, [_vm._v(" Catatan Ahli Gizi ")])], 1), _c('c-grid-item', {
    attrs: {
      "display": "flex",
      "align-items": "flex-end",
      "padding": "10px 0 10px 40px",
      "transition": "all 1s",
      "overflow": "hidden"
    }
  }, [_c('c-text', {
    staticClass: "line-clamp-2",
    attrs: {
      "width": "100%"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(((_vm$selectedListClien12 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien12 === void 0 ? void 0 : _vm$selectedListClien12.noteFromNutritionist) || '-')
    }
  })]), (_vm$selectedListClien13 = _vm.selectedListClientMealPlanDetail) !== null && _vm$selectedListClien13 !== void 0 && _vm$selectedListClien13.noteFromNutritionist ? _c('c-button', {
    attrs: {
      "variant": "link",
      "display": "contents",
      "font-weight": "400",
      "color": "primary.400"
    },
    on: {
      "click": function click($event) {
        return _vm.onOpenNoteList('nutritionist');
      }
    }
  }, [_vm._v(" Selengkapnya ")]) : _vm._e()], 1)], 1)], 1), _c('router-view', {
    attrs: {
      "is-edited": _vm.isEdited,
      "is-program-consultation-basic": _vm.isProgramConsultationBasic
    }
  }), _c('ModalContentQuestionnaire', {
    attrs: {
      "is-open": _vm.isOpenQuestionnaire
    },
    on: {
      "close": _vm.onCloseQuestionnaire
    }
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenPopupConfirmSendToClient,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Apakah kamu yakin mengirim panduan makan ke klien?"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSendToClient
          },
          on: {
            "click": function click($event) {
              _vm.isOpenPopupConfirmSendToClient = false;
            }
          }
        }, [_vm._v(" Tidak ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingSendToClient
          },
          on: {
            "click": _vm.handleSendToClient
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenSuccessSendToClient,
      "image": require('@/assets/images/success-image.svg'),
      "title": "Panduan makan berhasil dikirim ke klien"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(0);
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenSuccessSendToNutritionist,
      "image": require('@/assets/images/success-image.svg'),
      "title": "Catatan revisi berhasil dikirim ke ahli gizi"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(0);
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenConfirmSendToNutritionist,
      "close-on-overlay-click": true,
      "size": "900px"
    },
    on: {
      "close": function close($event) {
        !_vm.isLoadingSendToNutritionist ? _vm.isOpenConfirmSendToNutritionist = false : '';
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "background-color": "primary.50",
            "font-size": "20px",
            "font-weight": "bold",
            "padding": "8px 24px"
          }
        }, [_vm._v(" Catatan revisi ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "padding": "8px 24px"
          }
        }, [_c('BaseEditor', {
          attrs: {
            "image-provider-url": "/v1/admin/meal-plans/upload",
            "viewport-top-offset": {
              top: 0
            }
          },
          model: {
            value: _vm.note,
            callback: function callback($$v) {
              _vm.note = $$v;
            },
            expression: "note"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "50%",
            "padding": "0 24px",
            "margin": "auto",
            "margin-bottom": "16px",
            "margin-top": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "size": "small",
            "disabled": !_vm.note || _vm.isLoadingSendToNutritionist
          },
          on: {
            "click": _vm.handleSendToNutritionist
          }
        }, [_vm._v(" Kirim Revisi ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalMealPlanNoteHistory', {
    attrs: {
      "is-open": _vm.isOpenModalNote,
      "meal-plan-id": (_vm$selectedListClien14 = _vm.selectedListClientMealPlanDetail) === null || _vm$selectedListClien14 === void 0 ? void 0 : _vm$selectedListClien14.id,
      "type": _vm.typeModalNote
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalNote = false;
      }
    }
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenConfirmToRevision,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Konfimasi Permintaan Revisi",
      "description": "Panduan Makan telah diterima oleh klien. Meminta revisi akan mengubah isi yang sudah dikirim. Lanjutkan?"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenConfirmToRevision = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onConfirmRevision
          }
        }, [_vm._v(" Revisi ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('DrawerSpecificNote')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }